import React, { PropsWithChildren } from "react";
import { CloseButton } from "./CloseButton";

interface IProps {
    // open: boolean;
    // setOpen: (openIndex: number) => void;
    closeHelp: any;
}

export const TicketHelpPopup = (props: PropsWithChildren<IProps>) => {
    const { closeHelp } = props;

    return (
        <div className="popUp ticketHelpPopup">
            <CloseButton onClose={closeHelp} />
            <h2>Ticket Number</h2>
            <div className="text">
                <p>Find your ticket number near the barcode on your ticket!</p>
                <h3>Event Cinemas</h3>
                <p>Look under the barcode at the top of the ticket.</p>
                <h3>Hoyts</h3>
                <p>Look under the barcode at the bottom of the ticket.</p>
                <h3>Village Cinemas</h3>
                <p>Look under the barcode at the right of the ticket.</p>
                <h3>Other cinemas</h3>
                <p>Check for a barcode on your ticket; a ticket number will be present nearby.</p>
                <h3></h3>
                <div className="examples"></div>
            </div>
        </div>
    );
};
