import React, { useState, useEffect } from "react";
import { SEO } from "../components/SEO";
import ReactGA from "react-ga";
import { Form } from "../components/Form";
import { api } from "../lib/api";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { PrivacyPolicy } from "../components/PrivacyPolicy";
import { TermsAndConditions } from "../components/TermsAndConditions";
import { Overlay } from "../components/Overlay";
import { Success } from "../components/Success";
import { Social } from "../components/Social";
import { Failure } from "../components/Failure";
import { BuyNow } from "../components/BuyNow";
import { Description } from "../components/Description";
import { Faqs } from "../components/Faqs";
import { TicketHelpPopup } from "../components/TicketHelpPopup";
import { FormValues } from "../types/FormValues";

const Page = () => {
    const [isPrivacy, setIsPrivacy] = useState<boolean>(false);
    const [isTerms, setIsTerms] = useState<boolean>(false);
    const [isFaqs, setFaqs] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [failure, setFailure] = useState<boolean>(false);
    const [isHelp, setHelp] = useState<boolean>(false);

    const initialValues: FormValues = {
        fullName: "",
        email: "",
        phoneNumber: "",
        schoolName: "",
        classNumbers: "",
        tickets: [{ number: "", picture: undefined }],
        termsConditions: true,
        privacyPolicy: true,
        subscribe: true,
    };

    useEffect(() => {
        ReactGA.initialize(`${process.env.GATSBY_GOOGLE_ANALYTIC_ID}`);
        ReactGA.pageview(window.location.pathname + window.location.search);
    });

    const privacyOff = () => {
        setIsPrivacy(false);
    };

    const privacyOn = () => {
        setIsTerms(false);
        setFaqs(false);
        setHelp(false);
        setIsPrivacy(true);
    };

    const termsOff = () => {
        setIsTerms(false);
    };

    const termsOn = () => {
        setFaqs(false);
        setIsPrivacy(false);
        setHelp(false);
        setIsTerms(true);
    };

    const faqsOff = () => {
        setFaqs(false);
    };

    const faqsOn = () => {
        setIsPrivacy(false);
        setIsTerms(false);
        setHelp(false);
        setFaqs(true);
    };

    const helpOff = () => {
        setHelp(false);
    };

    const helpOn = () => {
        setIsPrivacy(false);
        setIsTerms(false);
        setFaqs(false);
        setHelp(true);
    };

    const successPage = () => {
        setSuccess(true);
    };

    const failurePage = () => {
        setFailure(true);
    };

    const tryAgain = () => {
        setFailure(false);
        ReactGA.event({
            category: "Form Submission failed",
            action: "Try Again",
        });
    };

    const addMoreTickets = () => {
        setSuccess(false);
        ReactGA.event({
            category: "Form Submission Success",
            action: "Add more tickets",
        });
    };

    return (
        <>
            <SEO
                title={"Watch to Win"}
                lang={"en-au"}
                description={"Watch The Wishmas Tree in Cinemas for the chance to win $5,000!"}
                meta={[]}
            />
            <Overlay />
            <Social />
            <Header />
            {success ? <Success onClick={addMoreTickets} /> : failure ? <Failure onClick={tryAgain} /> : null}
            <div style={failure || success ? { display: "none" } : {}}>
                <Description />
                <BuyNow url={`${process.env.GATSBY_TICKETS_URL}`} />
                <Form
                    api={api}
                    successPage={successPage}
                    failurePage={failurePage}
                    openPrivacy={privacyOn}
                    openTerms={termsOn}
                    initialValues={initialValues}
                    openHelp={helpOn}
                />
            </div>
            <Footer openPrivacy={privacyOn} openTerms={termsOn} openFaqs={faqsOn} />
            {isPrivacy ? <PrivacyPolicy closePrivacy={privacyOff} /> : null}
            {isTerms ? <TermsAndConditions closeTerms={termsOff} /> : null}
            {isFaqs ? <Faqs closeFaqs={faqsOff} /> : null}
            {isHelp ? <TicketHelpPopup closeHelp={helpOff} /> : null}
        </>
    );
};

export default Page;
