/* eslint-disable react/jsx-no-target-blank */
import React, { PropsWithChildren } from "react";
import { SuccessShare } from "./SuccessShare";
import { SuccessThanks } from "./SuccessThanks";
import ReactGA from "react-ga";

interface IProps {
    onClick: () => void;
}

const visitSanctuary = () => {
    ReactGA.event({
        category: "Form Submission Success",
        action: "Visit Sanctuary City",
    });
};

export const Success = (props: PropsWithChildren<IProps>) => {
    const { onClick } = props;
    return (
        <div className="success">
            <SuccessThanks />
            <p>Thanks for entering the competition. Good Luck!</p>
            <SuccessShare />
            <a
                className={"solid-white"}
                href="https://www.visitsanctuarycity.com/"
                target="_blank"
                onClick={visitSanctuary}
            >
                Explore more of Sanctuary City
            </a>
            <button type="button" className="solid-white" onClick={onClick}>
                Add more tickets
            </button>
        </div>
    );
};
