import React, { PropsWithChildren, useState, useEffect } from "react";
import Autosuggest, { Theme } from "react-autosuggest";
import { TextField } from "./TextField";
import { IAPI } from "../../common/Interfaces/IAPI";

interface IProps {
    setFieldValue: Function;
    errors: any;
    touched: any;
    handleBlur: Function;
    api: IAPI;
}

const theme: Theme = {
    container: {
        position: "relative",
    },
    inputFocused: {
        outline: "none",
    },
    inputOpen: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    suggestionsContainer: {
        display: "none",
    },
    suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        top: 45,
        width: "100%",
        border: "none",
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.5)",
        backgroundColor: "#fff",
        fontWeight: 300,
        fontSize: 16,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        zIndex: 2,
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: "none",
    },
    suggestion: {
        cursor: "pointer",
        padding: "10px 20px",
        color: "#000",
    },
    suggestionHighlighted: {
        backgroundColor: "#ddd",
    },
};

type School = {
    name: string;
};

type Response = {
    school: School[];
};

export const AutoComplete = (props: PropsWithChildren<IProps>) => {
    const [schoolName, setSchool] = useState("");
    const [suggestions, setSuggestions] = useState<School[]>([]);
    const [dbSchool, setDBSchool] = useState<School[]>([]);

    const { setFieldValue, errors, touched, handleBlur, api } = props;

    useEffect(() => {
        const getSchoolList = async () => {
            try {
                const result = await api.get<Response>("school");
                setDBSchool(result.school);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error.message);
            }
        };
        getSchoolList();
    }, []);

    const getSuggestionValue = (suggestion: School) => {
        return suggestion.name;
    };

    return (
        <>
            <Autosuggest
                suggestions={suggestions}
                theme={theme}
                onSuggestionsFetchRequested={async ({ value }) => {
                    const inputValue = value.trim().toLowerCase();
                    const inputLength = inputValue.length;
                    if (inputLength === 0) {
                        setSuggestions([]);
                        return;
                    } else {
                        const options = dbSchool.filter((school) => {
                            return school.name.toLowerCase().slice(0, inputLength) === inputValue;
                        });
                        setSuggestions(options);
                        return;
                    }
                }}
                onSuggestionsClearRequested={() => {
                    setSuggestions([]);
                }}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={(suggestion) => <div>{suggestion.name}</div>}
                onSuggestionSelected={(event, { suggestion, method }) => {
                    if (method === "enter") {
                        event.preventDefault();
                    }
                    setSchool(suggestion.name);
                    setFieldValue("schoolName", suggestion.name);
                }}
                focusInputOnSuggestionClick={false}
                inputProps={{
                    placeholder: "e.g. Rocklea State Primary School",
                    autoComplete: "abcd",
                    value: schoolName,
                    name: "schoolName",
                    className: touched && errors ? "is-danger" : "",
                    onChange: (_event, { newValue }) => {
                        setSchool(newValue);
                        setFieldValue("schoolName", newValue);
                    },
                }}
                renderInputComponent={(inputProps: any) => (
                    <TextField {...inputProps} error={touched ? errors : undefined} onBlur={handleBlur} ref={null} />
                )}
            />
            {touched && errors ? (
                <>
                    <div className={"error-icon"} />
                    <div className={"error-message"}>
                        <div className={"triangle"} />
                        <div className="help is-danger">{errors}</div>
                    </div>
                </>
            ) : null}
        </>
    );
};
