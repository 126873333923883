/* eslint-disable react/jsx-no-target-blank */
import React, { PropsWithChildren } from "react";

interface IProps {
    url: string;
}

export const BuyNow = (props: PropsWithChildren<IProps>) => {
    const { url } = props;
    return (
        <div className="buy-now">
            Don&rsquo;t have tickets yet?{" "}
            <a href={url} target="_blank">
                Buy them now!
            </a>
        </div>
    );
};
