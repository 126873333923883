/* eslint-disable react/jsx-no-target-blank */
import React, { PropsWithChildren } from "react";

interface IProps {
    openPrivacy: () => void;
    openTerms: () => void;
    openFaqs: () => void;
}

export const Footer = (props: PropsWithChildren<IProps>) => {
    const { children, openPrivacy, openTerms, openFaqs } = props;
    return (
        <footer>
            <div className="credits">
                <ul className="logos">
                    <li>
                        <a href="https://www.screenaustralia.gov.au/" target="_blank">
                            <div className="logoLinks" id="screenAus" />
                        </a>
                    </li>
                    <li>
                        <a href="https://screenqueensland.com.au/" target="_blank">
                            <div className="logoLinks" id="screenQld" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.likeaphoton.com/" target="_blank">
                            <div className="logoLinks" id="lapc" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.ponyuppicturestudios.com/" target="_blank">
                            <div className="logoLinks" id="ponyUp" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.universalpictures.com.au/" target="_blank">
                            <div className="logoLinks" id="universal" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.odinseyeent.com/" target="_blank">
                            <div className="logoLinks" id="odinsEye" />
                        </a>
                    </li>
                </ul>
            </div>
            <div className="type">&copy; {new Date().getFullYear()} Like a Photon Creative</div> |{" "}
            <a id="privacyLink" onClick={openPrivacy}>
                Privacy Policy
            </a>{" "}
            |{" "}
            <a id="termsLink" onClick={openTerms}>
                Terms &amp; Conditions
            </a>
            |{" "}
            <a id="privacyLink" onClick={openFaqs}>
                Frequently Asked Questions
            </a>{" "}
            {children}
        </footer>
    );
};
