import { FormikTouched, getIn } from "formik";
import React, { PropsWithChildren } from "react";
import { FormErrors } from "../types/FormErrors";
import { FormGetFieldProps } from "../types/FormGetFieldProps";
import { TextField } from "./TextField";

interface IProps {
    index: number;
    errors: FormErrors;
    touched: FormikTouched<unknown>;
    getFieldProps: FormGetFieldProps;
}

export const TicketNumberField = (props: PropsWithChildren<IProps>) => {
    const { index, errors, touched, getFieldProps } = props;
    return (
        <TextField
            name={`tickets[${index}].number`}
            error={
                getIn(errors, `tickets[${index}].number`) && getIn(touched, `tickets[${index}].number`)
                    ? getIn(errors, `tickets[${index}].number`)
                    : null
            }
            {...getFieldProps(`tickets[${index}].number`)}
        />
    );
};
