import React, { PropsWithChildren } from "react";
import { FacebookShareButton } from "react-share";
import ReactGA from "react-ga";

interface IProps {}

export const SuccessShare = (props: PropsWithChildren<IProps>) => {
    const { children } = props;

    const facebookShare = () => {
        ReactGA.event({
            category: "Form Submission Success",
            action: "Facebook Share",
        });
    };

    return (
        <div className="facebook-share" onClick={facebookShare}>
            <FacebookShareButton
                url={`${process.env.GATSBY_ROOT_URL}`}
                style={{ display: "flex", alignItems: "center" }}
            >
                Share on Facebook <span className={"fb-icon"} />
            </FacebookShareButton>
            {children}
        </div>
    );
};
