import { useRef, useEffect, MutableRefObject } from "react";
import { useFormikContext } from "formik";

type Props = {
    fieldRef: MutableRefObject<HTMLElement | undefined>;
    name: string;
};

export const useFocusOnError = (props: Props) => {
    const { fieldRef, name } = props;
    const formik = useFormikContext();
    const prevSubmitCountRef = useRef(formik.submitCount);
    const firstErrorKey = Object.keys(formik.errors)[0];
    useEffect(() => {
        if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
            if (fieldRef.current && firstErrorKey === name) {
                fieldRef.current.focus();
            }
        }
        prevSubmitCountRef.current = formik.submitCount;
    }, [formik.submitCount, formik.isValid, firstErrorKey]);
};
