import React, { PropsWithChildren, useState } from "react";
import "../scss/index.scss";
import { Formik } from "formik";
import { ticketValidation } from "../../common/functions/inputDataValidation";
import { FormTicketValue } from "../types/FormTicketValue";
import { FormValues } from "../types/FormValues";
import { Label } from "./Label";
import { TextField } from "./TextField";
import { IAPI } from "../../common/Interfaces/IAPI";
import ReactGA from "react-ga";
import { AutoComplete } from "./AutoComplete";
import { Spinner } from "./Spinner";

import { Ticket } from "./Ticket";

interface IProps {
    api: IAPI;
    successPage: () => void;
    failurePage: () => void;
    openPrivacy: () => void;
    openTerms: () => void;
    initialValues: FormValues;
    openHelp: any;
}

type SubmitFormResponseTicket = {
    number: string;
    picture: string;
    type: string;
};

type SubmitFormResponse = {
    id: string;
    tickets: SubmitFormResponseTicket[];
};

type SetValues = (...args: any[]) => void;

export const Form = (props: PropsWithChildren<IProps>) => {
    const { api, successPage, failurePage, openTerms, openPrivacy, initialValues, openHelp } = props;
    const [isLoading, setIsLoading] = useState<boolean>();
    const [iterations, setIterations] = useState(1);
    let formikSetValues: SetValues;

    const handleAnalytics = () => {
        ReactGA.event({
            category: "Form Submit Button",
            action: "Form Submission",
        });
    };

    const mapFileToBlob = (file: File): Promise<Blob> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener("loadend", (e) => {
                resolve(new Blob([reader.result as ArrayBuffer], { type: file.type }));
            });
            reader.addEventListener("error", reject);
            reader.readAsArrayBuffer(file);
        });
    };

    const uploadTicket = async (responseTicket: SubmitFormResponseTicket, inputTicket: FormTicketValue) => {
        const { picture: file } = inputTicket;
        const { picture: url } = responseTicket;

        if (!file) {
            throw new Error("File does not exist.");
        }

        const blob = await mapFileToBlob(file);

        await fetch(url, {
            method: "PUT",
            body: blob,
        });
    };

    const onSubmit = async (values: FormValues) => {
        setIsLoading(true);
        try {
            values.tickets = values.tickets.map((ticket) => {
                ticket.type = ticket.picture ? ticket.picture.type : "";
                return ticket;
            });

            const result = await api.post<SubmitFormResponse>("submitForm", values);

            await Promise.all(
                result.tickets.map((responseTicket: SubmitFormResponseTicket, index: number) => {
                    return uploadTicket(responseTicket, values.tickets[index]);
                }),
            );
            setIterations(iterations + 1);
            if (formikSetValues) {
                formikSetValues({
                    ...values,
                    tickets: [{ number: "", picture: undefined }],
                });
            }

            setIsLoading(false);
            successPage();
            ReactGA.event({
                category: "Form Submission success",
                action: "Successful",
            });
        } catch (error) {
            setIsLoading(false);
            failurePage();
            ReactGA.event({
                category: "Form Submission failed",
                action: "Unsuccessful",
            });
        }
    };

    return (
        <Formik initialValues={initialValues} validationSchema={ticketValidation} onSubmit={onSubmit}>
            {({ handleSubmit, getFieldProps, touched, errors, setFieldValue, values, handleBlur, setValues }) => {
                formikSetValues = setValues;
                return (
                    <form onSubmit={handleSubmit}>
                        <div>
                            <Label name="fullName">Full Name</Label>
                            <TextField
                                name="fullName"
                                placeholder="e.g. Jane Doe"
                                error={touched.fullName ? errors.fullName : undefined}
                                {...getFieldProps("fullName")}
                            />
                        </div>
                        <div>
                            <Label name="email">Email Address</Label>
                            <TextField
                                name="email"
                                placeholder="e.g. jane@email.com"
                                error={touched.email ? errors.email : undefined}
                                {...getFieldProps("email")}
                            />
                        </div>
                        <div>
                            <Label name="phoneNumber">Phone Number (Optional)</Label>
                            <TextField
                                name="phoneNumber"
                                placeholder="e.g. +00 000 000 000"
                                error={touched.phoneNumber ? errors.phoneNumber : undefined}
                                {...getFieldProps("phoneNumber")}
                            />
                        </div>
                        <div>
                            <Label name="schoolName">School or Club Name</Label>
                            <div className={"control"}>
                                <AutoComplete
                                    setFieldValue={setFieldValue}
                                    touched={touched.schoolName}
                                    errors={errors.schoolName}
                                    handleBlur={handleBlur}
                                    api={api}
                                />
                            </div>
                        </div>
                        <div>
                            <Label name="classNumbers">Class or Team Name</Label>
                            <TextField
                                name="classNumbers"
                                placeholder="e.g. Prep C"
                                error={touched.classNumbers ? errors.classNumbers : undefined}
                                {...getFieldProps("classNumbers")}
                            />
                        </div>
                        <div>
                            <Label name="tickets">Add up to 5 tickets</Label>
                            <Ticket
                                iterations={iterations}
                                values={values}
                                errors={errors}
                                touched={touched}
                                getFieldProps={getFieldProps}
                                setFieldValue={setFieldValue}
                                handleBlur={handleBlur}
                                openHelp={openHelp}
                                tickets={values.tickets.length}
                            />
                        </div>
                        <div className="checkbox">
                            <div className="checkbox-content">
                                <input
                                    type="checkbox"
                                    name="termsConditions"
                                    defaultChecked
                                    {...getFieldProps("termsConditions")}
                                />
                                <p>
                                    I agree to the{" "}
                                    <span onClick={openTerms} className="terms-privacy">
                                        Terms and Conditions{" "}
                                    </span>
                                </p>
                            </div>
                            {errors.termsConditions ? <p className="error-msg"> {errors.termsConditions} </p> : null}
                        </div>
                        <div className="checkbox">
                            <div className="checkbox-content">
                                <input
                                    type="checkbox"
                                    name="privacyPolicy"
                                    defaultChecked
                                    {...getFieldProps("privacyPolicy")}
                                />
                                <p>
                                    I agree to the{" "}
                                    <span onClick={openPrivacy} className="terms-privacy">
                                        Privacy Policy
                                    </span>
                                </p>
                            </div>
                            {errors.privacyPolicy ? <p className="error-msg"> {errors.privacyPolicy} </p> : null}
                        </div>
                        <div className="checkbox">
                            <div className="checkbox-content">
                                <input
                                    type="checkbox"
                                    name="subscribe"
                                    defaultChecked
                                    {...getFieldProps("subscribe")}
                                />
                                <p>Subscribe to Tales from Sanctuary City</p>
                            </div>
                        </div>
                        {isLoading ? (
                            <Spinner />
                        ) : (
                            <button type="submit" id="submit" onClick={handleAnalytics}>
                                ENTER THE COMPETITION!
                            </button>
                        )}
                        {Object.keys(errors).length > 0 ? (
                            <div className="form-error">
                                <div className="error-icon" />
                                <img src={require("../images/errorIcon_23x21.png")} alt={"Error"} />
                                <p>Please complete all required fields</p>
                            </div>
                        ) : null}
                    </form>
                );
            }}
        </Formik>
    );
};
