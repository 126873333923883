import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { PropsWithChildren } from "react";

interface IProps {}

export const SuccessThanks = (props: PropsWithChildren<IProps>) => {
    const { children } = props;
    return (
        <>
            <FontAwesomeIcon icon={faCheckCircle} size="4x" />
            <h4>Thank you!</h4>
            {children}
        </>
    );
};
