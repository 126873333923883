import { APIRequestTypes, IAPI, IAPIConfig, IAPIRequestParameters } from "../Interfaces/IAPI";

/**
 * Creates an API instance.
 *
 * @author Cam Morrow
 *
 * @param {IAPIConfig}  apiConfig
 */
export const apiFactory = (apiConfig: IAPIConfig): IAPI => {
    let readyResolver: () => void;
    const ready = new Promise<void>((resolve) => {
        readyResolver = resolve;
    });

    const bootstrap = async () => {
        readyResolver();
    };

    const request = async <TPayload>(
        type: APIRequestTypes,
        path: string,
        parameters: IAPIRequestParameters | FormData = {},
    ): Promise<TPayload> => {
        return apiConfig.requestQueuer<TPayload>(type, path, parameters);
    };

    const sendFile = async <TPayload>(path: string, parameters?: FormData): Promise<TPayload> => {
        return request("POST", path, parameters);
    };

    const get = async <TPayload>(path: string, parameters?: IAPIRequestParameters): Promise<TPayload> => {
        return request("GET", path, parameters);
    };

    const post = async <TPayload>(path: string, parameters?: IAPIRequestParameters): Promise<TPayload> => {
        return request("POST", path, parameters);
    };

    const remove = async <TPayload>(path: string, parameters?: IAPIRequestParameters): Promise<TPayload> => {
        return request("DELETE", path, parameters);
    };

    const put = async <TPayload>(path: string, parameters?: IAPIRequestParameters): Promise<TPayload> => {
        return request("PUT", path, parameters);
    };

    return {
        bootstrap,
        ready,
        get,
        post,
        put,
        remove,
        sendFile,
        request,
    };
};
