import React, { PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    onClick: () => void;
}

export const Failure = (props: PropsWithChildren<IProps>) => {
    const { onClick } = props;
    return (
        <div className="failure">
            <FontAwesomeIcon icon={faTimesCircle} size="4x" style={{ color: "#E13700" }} />
            <h4 className={"error"}>Oops!</h4>
            <p>Something went wrong!</p>
            <button type="button" className="solid-white" onClick={onClick}>
                Try again
            </button>
        </div>
    );
};
