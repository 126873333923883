import { FieldArray } from "formik";
import React, { PropsWithChildren } from "react";
import { FormErrors } from "../types/FormErrors";
import { FormGetFieldProps } from "../types/FormGetFieldProps";
import { FormHandleBlur } from "../types/FormHandleBlur";
import { FormSetFieldValue } from "../types/FormSetFieldValue";
import { FormTouched } from "../types/FormTouched";
import { FormValues } from "../types/FormValues";
import { Label } from "./Label";
import { TicketFileField } from "./TicketFileField";
import { TicketNumberField } from "./TicketNumberField";

interface IProps {
    values: FormValues;
    errors: FormErrors;
    touched: FormTouched;
    getFieldProps: FormGetFieldProps;
    setFieldValue: FormSetFieldValue;
    handleBlur: FormHandleBlur;
    iterations: number;
    openHelp: any;
    tickets: number;
}

export const Ticket = (props: PropsWithChildren<IProps>) => {
    const { values, errors, touched, getFieldProps, setFieldValue, handleBlur, iterations, openHelp, tickets } = props;

    return (
        <FieldArray
            name="tickets"
            render={({ remove, push }) => {
                return (
                    <>
                        <div>
                            {values.tickets.map((ticket, index) => (
                                <div className="ticket" key={`${iterations}-${index}`}>
                                    <Label name="tickets">Ticket Number #{index + 1}</Label>
                                    <a
                                        className="ticketHelp"
                                        href={"#"}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            openHelp();
                                        }}
                                    />
                                    <TicketNumberField
                                        index={index}
                                        errors={errors}
                                        touched={touched}
                                        getFieldProps={getFieldProps}
                                    />
                                    <TicketFileField
                                        index={index}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                    />
                                    {values.tickets.length > 1 ? (
                                        <button type="button" id="remove" key={index} onClick={() => remove(index)} />
                                    ) : null}
                                </div>
                            ))}
                        </div>
                        {tickets < 5 ? (
                            <button
                                type="button"
                                className="solid-white"
                                onClick={() => push({ number: "", picture: undefined })}
                            >
                                Add another ticket
                            </button>
                        ) : null}
                    </>
                );
            }}
        />
    );
};
