import * as Yup from "yup";

const FILE_SIZE = 10000000; //10mb
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/heic"];

export const ticketValidation = Yup.object().shape({
    fullName: Yup.string()
        .max(15, "Must be 50 characters or less")
        .required("Full Name is Required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email Address is Required"),
    schoolName: Yup.string().required("School or Club Name is Required"),
    classNumbers: Yup.string(),
    tickets: Yup.array().of(
        Yup.object().shape({
            number: Yup.string().required("Ticket Number is required"),
            picture: Yup.mixed()
                .required("Ticket Photo is required.")
                .test("fileFormat", "Unsupported Format", (value) => value && SUPPORTED_FORMATS.includes(value.type))
                .test("fileSize", "File too large, up to 10mb allowed", (value) => value && value.size <= FILE_SIZE),
        }),
    ),
    termsConditions: Yup.boolean()
        .required("Must be agree to our terms & coditions.")
        .oneOf([true], "Must be agreed to our terms & conditions."),
    privacyPolicy: Yup.boolean()
        .required("Must be agree to our privacy policy.")
        .oneOf([true], "Must be agreed to our privacy policy."),
    subscribe: Yup.boolean(),
});

export const ticketValidationBack = Yup.object().shape({
    fullName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Full Name is Required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email Address is Required"),
    schoolName: Yup.string().required("School or Club Name is Required"),
    classNumbers: Yup.string(),
    tickets: Yup.array().of(
        Yup.object().shape({
            number: Yup.string().required("Ticket Number is required"),
            picture: Yup.mixed(),
        }),
    ),
    termsConditions: Yup.boolean()
        .required("Must be agree to our terms & coditions.")
        .oneOf([true], "Must be agree to our terms & coditions."),
    privacyPolicy: Yup.boolean()
        .required("Must be agree to our privacy policy.")
        .oneOf([true], "Must be agree to our privacy policy."),
    subscribe: Yup.boolean(),
});

export const userValidation = Yup.object({
    fullName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Name is Required"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
    phoneNumber: Yup.string(),
});

export const schoolValidation = Yup.object({
    name: Yup.string().required("School name is Required"),
    address: Yup.string(),
});
