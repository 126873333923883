import { getIn } from "formik";
import React, { PropsWithChildren, SyntheticEvent } from "react";
import { FormErrors } from "../types/FormErrors";
import { FormSetFieldValue } from "../types/FormSetFieldValue";
import { FormTouched } from "../types/FormTouched";
import { FormHandleBlur } from "../types/FormHandleBlur";
import { TextField } from "./TextField";

interface IProps {
    index: number;
    errors: FormErrors;
    setFieldValue: FormSetFieldValue;
    touched: FormTouched;
    handleBlur: FormHandleBlur;
}

export const TicketFileField = (props: PropsWithChildren<IProps>) => {
    const { index, errors, setFieldValue, touched, handleBlur } = props;
    return (
        <TextField
            type="file"
            accept="image/*, .heic"
            name={`tickets.${index}.picture`}
            onBlur={handleBlur}
            onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                const target = event.target as HTMLInputElement;
                const { files } = target;
                if (files && files.length > 0) {
                    setFieldValue(`tickets[${index}].picture`, files[0]);
                }
            }}
            error={
                getIn(errors, `tickets[${index}].picture`) && getIn(touched, `tickets[${index}].picture`)
                    ? getIn(errors, `tickets[${index}].picture`)
                    : null
            }
        />
    );
};
