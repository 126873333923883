import React, { PropsWithChildren } from "react";

interface IProps {
    name: string;
}

export const Label = (props: PropsWithChildren<IProps>) => {
    const { children, name } = props;
    return (
        <label htmlFor={name} className={"label"}>
            {children}
        </label>
    );
};
