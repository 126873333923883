import ky, { Options } from "ky";
import PQueue from "p-queue";
import queryString from "query-string";
import { apiFactory } from "../../common/factories/apiFactory";
import { APIRequestTypes, IAPIRequestParameters, RequestQueuer } from "../../common/Interfaces/IAPI";

const requestQueuerGenerator = (): RequestQueuer => {
    const requestQueue = new PQueue({ concurrency: 1 });

    const requestQueuer: RequestQueuer = <TPayload>(
        method: APIRequestTypes,
        path: string,
        parameters: IAPIRequestParameters | FormData,
    ) => {
        return requestQueue.add(async () => {
            let uri = `/.netlify/functions/${path}`;
            const isGetOrDelete = method === "DELETE" || method === "GET";
            if (isGetOrDelete && parameters) {
                uri = `${uri}?${queryString.stringify(parameters)}`;
            }

            const options: Options = {
                method,
            };

            if (!isGetOrDelete && parameters) {
                if (parameters instanceof FormData) {
                    options.body = parameters;
                } else {
                    options.headers = {
                        "content-type": "application/json",
                    };
                    options.body = JSON.stringify(parameters);
                }
            }

            return ky(uri, options).json<TPayload>();
        });
    };

    return requestQueuer;
};

export const api = apiFactory({
    requestQueuer: requestQueuerGenerator(),
});
