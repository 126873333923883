import React from "react";

export const Description = () => {
    return (
        <header>
            <p>
                To celebrate the Australian release of <em>The Wishmas Tree</em>, the first animated film in the Tales
                from Sanctuary City movie series, we are giving your school, club or community group the chance to win{" "}
                <strong>$5000!</strong>
            </p>
            <p>
                To enter, head to your nearest cinema from <strong>February 27</strong> and watch{" "}
                <em>The Wishmas Tree</em>, keep your ticket, and enter the stub details right here!
            </p>
            <p>
                The competition runs from February 19th to March 26th. One entry per ticket, so grab your friends for
                more chances to win!
            </p>
            <p>
                Runners up will personally receive one of 20 DVDs, courtesy of Universal Content Group Home
                Entertainment.
            </p>
        </header>
    );
};
