/* eslint-disable react/jsx-no-target-blank */
import React, { PropsWithChildren } from "react";
import { CloseButton } from "./CloseButton";

interface IProps {
    closeFaqs: () => void;
}

export const Faqs = (props: PropsWithChildren<IProps>) => {
    const { closeFaqs } = props;

    return (
        <div className="popUp">
            <CloseButton onClose={closeFaqs} />
            <h2>Frequency Asked Questions</h2>
            <div className="text">
                <h4>From when is this competition running?</h4>
                <p>
                    The competition is open from the 19th of February until the 26th of March. Winners will be announced
                    soon after the competition closes.
                </p>
                <h4>Where can I see the movie?</h4>
                <p>
                    Go to{" "}
                    <a href={"https://www.flicks.com.au/the-wishmas-tree/"} target={"_blank"}>
                        https://www.flicks.com.au/the-wishmas-tree/
                    </a>{" "}
                    and search your local area to see where the film is playing.
                </p>
                <h4>How many times can I enter the competition?</h4>
                <p>
                    As many times as you like! As long as you have a valid ticket stub, you can enter the competition.
                </p>
                <h4>I&rsquo;ve got more than one ticket. Do I have to fill out the form multiple times?</h4>
                <p>
                    If you&rsquo;ve got multiple tickets, you can just click the &ldquo;Add another Ticket&rdquo; button
                    on your existing form. Each ticket counts as one entry for that club or school.
                </p>
                <p>
                    If you have more than five tickets, you&rsquo;ll need to add five at a time. Just choose &ldquo;Add
                    more tickets&rdquo;.
                </p>
                <h4>I have multiple tickets, but I want to enter each as a different school or club.</h4>
                <p>No worries! For this, you will just have to fill out multiple forms.</p>
                <h4>I&rsquo;m under the age of 18. Can I enter the competition?</h4>
                <p>
                    No, you&rsquo;ll need to have your parent, guardian or trusted supervisor enter the competition on
                    your behalf.
                </p>
                <h4>What do you mean by school, club or community group?</h4>
                <p>A school is any primary or secondary school recognised by its corresponding State government.</p>
                <p>
                    A club or community group is any secular sports club, community group or organisation catering to
                    children aged between 0 and 18, that is run on a not for profit basis.
                </p>
                <p>For-Profit childcare centres are not eligible for entry.</p>
                <h4>Do I have to enter a real school or club?</h4>
                <p>
                    Yes! We will be checking that the club or school you&rsquo;ve entered is real and eligible before
                    handing out the prize.
                </p>
                <h4>I didn&rsquo;t hear from you. Did I win?</h4>
                <p>
                    If you don&rsquo;t hear from us within 7 days of the competition closing, you haven&rsquo;t won. But
                    you saw a great movie, right? So we&rsquo;re all winners!
                </p>
            </div>
        </div>
    );
};
