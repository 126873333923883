import React, { PropsWithChildren, useRef } from "react";
import { useFocusOnError } from "../hooks/useFocusOnError";
import { FormHandleBlur } from "../types/FormHandleBlur";
import { FormOnChange } from "../types/FormOnChange";

interface IProps {
    name: string;
    placeholder?: string;
    error?: string;
    type?: string;
    required?: any;
    accept?: string;
    onBlur?: FormHandleBlur;
    onChange?: FormOnChange<any, any>;
}

export const TextField = (props: PropsWithChildren<IProps>) => {
    const { name, error, ...rest } = props;

    const fieldRef = useRef<HTMLInputElement>();
    useFocusOnError({ fieldRef, name });

    return (
        <div className={"control"}>
            <input ref={fieldRef} name={name} className={error ? "is-danger" : ""} {...rest} />
            {error ? (
                <>
                    <div className={"error-icon"} />
                    <div className={"error-message"}>
                        <div className={"triangle"} />
                        <div className="help is-danger">{error}</div>
                    </div>
                </>
            ) : null}
        </div>
    );
};
