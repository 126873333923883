import React from "react";
import ReactGA from "react-ga";

export const Social = () => {
    const visitFacebook = () => {
        ReactGA.event({
            category: "Visit Social Sites",
            action: "Visit Facebook",
        });
    };

    const visitInstagram = () => {
        ReactGA.event({
            category: "Visit Social Sites",
            action: "Visit Instagram",
        });
    };

    return (
        <header>
            <div className="socials">
                <ul className="icons">
                    <li>
                        <a
                            href="https://www.facebook.com/talesfromsanctuarycity"
                            target="_blank"
                            onClick={visitFacebook}
                        >
                            <div id="facebook" className="socialIcons" />
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://www.instagram.com/talesfromsanctuarycity/"
                            target="_blank"
                            onClick={visitInstagram}
                        >
                            <div id="instagram" className="socialIcons" />
                        </a>
                    </li>
                </ul>
            </div>
        </header>
    );
};
